<template>
  <div :class="$attrs.class">
    <button
        class="accordion-button btn btn-block btn-arrow"
        :class="{
          active: isActive,
          'border-top': border === 'top',
          'border-bottom': border === 'bottom',
          'border-both': border === 'both',
          '': 'none'
        }"
        @click="isActive = !isActive"
    >{{ title }}
      <i><svg xmlns="http://www.w3.org/2000/svg" width="10" height="18"><path d="M1 1l8 8-8 8" stroke="#060f21" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg></i>
    </button>
    <div v-if="isActive">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccordionComponent',
  data() {
    return {
      isActive: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    border: {
      type: String,
      default: 'top',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.isActive = this.open;
  },
};
</script>

<style scoped lang="scss">
.accordion-button {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-left: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0;
}

.border-top {
  border-top: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
}

.btn:focus {
  box-shadow: none!important;
}

.border-top.btn:focus {
  border-radius: 0!important;
  border-top: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
}

.border-bottom {
  border-bottom: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
}

.border-bottom.btn:focus {
  border-radius: 0!important;
  border-bottom: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
}

.border-both {
  border-top: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
  border-bottom: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
}

.border-both.btn:focus {
  border-radius: 0!important;
  border-top: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
  border-bottom: 2px solid var(--Colors-Grey-grey-200, #E4E8E8)!important;
}

.btn.btn-arrow i {
  right: 5px!important;
}
</style>
